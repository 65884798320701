@import './variables.scss';
.ant-btn-default {
  width: auto;
  background-color: $second-button-color;
  border-color: $second-button-color;
  color: #fff;
  &:hover {
    background-color: $second-button-color-hover;
    border-color: $second-button-color-hover;

    color: $font-light;
  }

  &:active,
  &:focus {
    background-color: $second-button-color-hover;
    border-color: $second-button-color-hover;
    color: $font-light;
    border: 0;
  }
}
