.doctor-schedule-page {
    font-family: Arial, sans-serif;
    color: #333;
  
    .header {
      display: flex;
      align-items: center;
      background-color: #002a52;
      color: #f9f9f9;
      padding: 10px 15px;
  
      .back-arrow {
        font-size: 1.5rem;
        cursor: pointer;
        margin-right: 10px;
        color: #f9f9f9;

      }
  
      .doctor-title {
        margin: 0;
        font-size: 1.5rem;
      }
    }
  
    .content {
      margin: 20px;
  
      .calendar-section {
        display: flex;
        justify-content: center;
        padding: 10px;
  
        /* En móvil, asegúrate de centrar el calendario */
        @media (max-width: 768px) {
          padding: 0;
          margin-bottom: 5px;
        }
      }
  
      .details-section {
        margin: 10px;
        background-color: #f1f1f1;
        padding: 10px;
        border-radius: 10px;
  
  
        .selected-date {
          text-align: center;
          font-size: 12pt;
          color: #1890ff;
        }
  
        .doctor-description {
          margin-top: 15px;
  
          h3 {
            margin-bottom: 5px;
            font-size: 1.1rem;
            color: #333;
          }
  
          p {
            font-size: 1rem;
            line-height: 1.4;
          }
        }
      }
    }
  }
  
  