@import '../../scss/index.scss';

.online {
    flex: 1;

    &-collapse {
      background-color: #E9E9E9;
    }

    &-hours {
      flex-direction: row;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
 
      

    &-box {
      border: 1px solid #E9E9E9;
      padding: 1%;
      margin: 1%;
      border-radius: 5px;
      background-color: #E9E9E9;
      text-align: center;
      flex-wrap: wrap;


      ul {
        padding-inline-start: 15px;
        flex-wrap: wrap;
        @media (max-width: $media-break-point-up-sm) {
          font-size: 8pt;
        } 
      }
      span {
        text-align: center;
        font-weight: bold;
      }
    }
  }

  &-description {
    h4 { 
      text-align: center;
    }

    p {
      font-weight: normal;
      font-size: 13px;
      margin-bottom: 0.5%;
      white-space: pre-wrap;
    }
    border: 1px;
    background-color: #E9E9E9;
    padding: 5px 20px 5px 20px;
    border-radius: 5px;
    margin: 5px;
  }


    .ant-card {
        margin-bottom: 5px;
      }

      .ant-result {
        padding-top: 0;
      }

      h1 {text-align: center}
      .ant-card-head {
        text-align: center;
        height: 10px;
        background-color: $primary-button-color;
        color: #fff;
        font-size: 12pt;
     
        @media (max-width: $media-break-point-up-sm) {
          font-size: 10pt;
        } 
      }

      .ant-card-body {
        @media (min-width: $media-break-point-up-lg) {
          padding: 16px;
        }
  
 
      }

      .ant-descriptions {
        border: 1px solid ;
        padding: 5px;
        border-radius: 2px;

        span {
            margin-right: 2px;        
            font-size: 11pt;
        }
      }

      &-studies {
        text-align: center;
      }
    

       &-logo {
            width: 90%;
            display: flex;
            padding: 5%;
            max-height: 50vh;
            text-align: center;
        
            img {
                width: 100%;
                object-fit: contain;
                aspect-ratio: 3/2;
              }    
          }


       &-mini-logo {
        text-align: center;
        font-size: 12pt;
        font-weight: bold;

       
        
    
        img {
            object-fit: contain;
            height: 200px;
          }    
      }


       &-warn {
        margin-top: 10px;
        padding: 5px;
        background-color: #e6fb02
       }

}

.ant-picker-header-super-prev-btn,
.ant-picker-header-super-next-btn {
  display: none;
}


.detail {
    background-color: blue;
}