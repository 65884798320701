.custom-calendar {
    width: 300px;
    margin: auto;
    text-align: center;
    font-family: Arial, sans-serif;
  
    .calendar-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      background-color: #3f7fba;
      padding: 10px;
      border-radius: 10px;
      color: #fff;

  
      .month-label {
        margin: 0;
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: capitalize;
      }
  
      .nav-arrow {
        font-size: 1.2rem;
        cursor: pointer;
        color: #fff;
        transition: color 0.3s;
  
        &:hover {
          color: #3f7fba;
        }
      }
    }
  
    .weekdays {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      font-weight: bold;
      margin-bottom: 5px;
  
      .weekday {
        padding: 5px 0;
        text-align: center;
      }
    }
  
    .calendar-grid {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: 5px;
  
      .calendar-day {
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 8px;
        background: #f5f5f5;
        cursor: pointer;
        transition: background 0.3s, color 0.3s;
  
        &:hover {
          background: #e6f7ff;
        }
  
        &.selected {
          background: #1764ab;
          color: white;
          font-weight: bold;
        }
      }
  
      .empty-day {
        background: transparent;
        cursor: default;
      }
    }
  }
  