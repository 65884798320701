$default-font-size: 16px;

$primary-button-color: #002a52;
$primary-button-color-hover: #003566;
$second-button-color: #1764ab;
$second-button-color-hover: #3f7fba;

$primary-color: #0098d3;
$primary-color-hover: #0280b3;

$menu-color: #252527;

$font-light: #ffff;
$font-grey-light: #e8e8e8;
$font-grey: #808080;
$font-grey-dark: #000;
$font-dark-light: #252527;
$font-dark: #000;

$border-light: #ffff;
$border-grey-light: #e8e8e8;
$border-grey: #25776c;
$border-grey-dark: #595959;
$border-dark-light: #252527;
$border-dark: #000;

$background-light: #ffff;
$background-grey-light: #e8e8e8;
$background-grey: #25776c;
$background-grey-dark: #595959;
$background-dark-light: #252527;
$background-dark: #000;
$background-error: #ff00001f;
$background-success: #84b84c1f;

$success: #84b84c;
$error: #f00;

$media-break-point-down-xs: 413.98px;
$media-break-point-down-sm: 573.98px;
$media-break-point-down-md: 767.98px;
$media-break-point-down-lg: 991.98px;
$media-break-point-down-xl: 1119.98px;

$media-break-point-up-xs: 320x;
$media-break-point-up-sm: 576px;
$media-break-point-up-md: 768px;
$media-break-point-up-lg: 992px;
$media-break-point-up-xl: 1200px;
