.services-list {
    display: flex;
    flex-direction: column;
    gap: 15px; // Espaciado entre los ítems
    padding: 0;
  
    .service-item {
      background: #f9f9f9;
      border-radius: 8px;
      padding: 12px 16px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease-in-out;
  
      &:hover {
        transform: translateY(-5px);
        background-color: #f0f2f5;
      }
  
      .service-name {
        font-size: 16px;
        font-weight: 600;
        color: #002a52;
        margin: 0 0 4px 0;
      }
  
      .service-description {
        font-size: 14px;
        color: #666;
        margin: 0;
      }
    }
  }
  